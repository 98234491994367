import React from "react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div>
      Home page
      <Nav className="flex-column ps-3">
        <Link to="/">Anasayfa</Link>
        <Link to="/survey">Anket</Link>
      </Nav>
    </div>
  );
};

export default Home;
