import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const vowApi = createApi({
  reducerPath: "vowApi",
  baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:3000/" }),
  endpoints: (builder) => ({
    getResult: builder.query({
      query: (name) => `lıgin-wage-result`,
    }),
    sendForm: builder.mutation({
      query: (body) => ({
        url: `living-wage`,
        method: "POST",
        body: body,
      }),
    }),
    userLogin: builder.mutation({
      query: (data) => ({
        url: `login`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useSendFormMutation, useUserLoginMutation } = vowApi;
