import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { vowApi } from "./api";
import {jwtDecode} from 'jwt-decode';
//import authReducer from "./authSlice";
import AuthMiddleware from './authMiddleware';


// const loadToken = () => {
//   try {
//     const userToken = localStorage.getItem("userToken");
//     if (userToken === null) {
//       return null;
//     }

//     const userInfo = jwtDecode(userToken);

//     return {
//       userToken,
//       userInfo,
//     };
//   } catch (e) {
//     return null;
//   }
// };

export const store = configureStore({
  reducer: {
    //auth: authReducer,
    [vowApi.reducerPath]: vowApi.reducer,
  },
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare().concat(vowApi.middleware),
  // preloadedState: {
  //   auth: { ...loadToken() },
  // },
});

export default store;
