import React from "react";
import { useEffect, useState } from "react";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Calculate from "../components/calculate";
import { DevTool } from "@hookform/devtools";
import { useSendFormMutation } from "../store/api";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from 'react-router-dom';

function LivingWageSurvey() {
  const [sendForm, { isLoading, isSuccess }] = useSendFormMutation();
  const [showModal, setShowModal] = useState(false);
  let { companyId } = useParams();

  useEffect(() => {
    if (isSuccess) {
      setShowModal(true);
    }
  }, [isSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      currency: "TL",
      number_of_people_generate_income: 3,
      number_of_children: 2,
      adult_in_eduction: 2,
      energy_source: "Soba",
      transport_vehicle: "Otobüs",
      distance_home_to_work: 2,
      distance_shop_to_home: 2,
      distance_school_to_home: 2,
      distance_hospital_to_home: 2,
      access_to_water: false,
      education_expense: 10,
      transportation_expense: 100,
      food_expense: 200,
      clothing_expense: 200,
      fuel_and_water_expense: 500,
      housing_expense: 300,
      unexpected_expense: 400,
      savings: 200,
    },
  });
  const submit = (data) => {
    const returnedTarget = Object.assign(data, {
      company_id: "1ff264f5-7c56-4e7f-8355-b40fbcbac4d0",
      season: "2023",
    });
    sendForm(returnedTarget);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="App">
      <h4 className="my-5 text-center">Living Wage Survey</h4>
      <Container>
        <Row>
          <Col lg={10} className="mb-4">
            <h5 className="text-center">Adil Yaşam Ücreti Anketi</h5>
          </Col>
          <Col lg={10} className="mb-4">
            <Form onSubmit={handleSubmit(submit)} noValidate>
              <Row>
                <Col lg={10} xs={12}>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      Yerel Para Birimi
                    </Form.Label>
                    <Col sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        className={errors.currency ? "border-danger" : ""}
                        {...register("currency", { required: true })}
                      />
                      <div errors={errors} name="currency">
                        {errors.currency?.message}
                      </div>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      Haneye gelir getiren kişi sayısı
                    </Form.Label>
                    <Col className="d-flex" sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        className={
                          errors.number_of_people_generate_income
                            ? "border-danger"
                            : ""
                        }
                        {...register("number_of_people_generate_income", {
                          required: true,
                        })}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      Hanedeki çocuk sayısı
                    </Form.Label>
                    <Col className="d-flex" sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        className={
                          errors.number_of_children ? "border-danger" : ""
                        }
                        {...register("number_of_children", { required: true })}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      Bir ailede işten sonra eğitim kursuna katılan ortalama
                      yetişkin sayısı:
                    </Form.Label>
                    <Col sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        className={
                          errors.adult_in_eduction ? "border-danger" : ""
                        }
                        {...register("adult_in_eduction", {
                          required: true,
                        })}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      Handeki ısınma için kullanılan enerji kaynağı:
                    </Form.Label>
                    <Col sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        className={errors.energy_source ? "border-danger" : ""}
                        {...register("energy_source", { required: true })}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      Ulaşım için hangi araçlar kullanılıyor
                    </Form.Label>
                    <Col sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        className={
                          errors.transport_vehicle ? "border-danger" : ""
                        }
                        {...register("transport_vehicle", { required: true })}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      Ev ile iş arası kaç km
                    </Form.Label>
                    <Col sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        className={
                          errors.distance_home_to_work ? "border-danger" : ""
                        }
                        {...register("distance_home_to_work", {
                          required: true,
                        })}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      En yakın alışveriş merkezine uzaklık ne kadar?
                    </Form.Label>
                    <Col sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        className={
                          errors.rangeToShdistance_shop_to_homeopping
                            ? "border-danger"
                            : ""
                        }
                        {...register("distance_shop_to_home", {
                          required: true,
                        })}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      En yakın okul ne kadar uzaklıkta km
                    </Form.Label>
                    <Col sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        className={
                          errors.distance_school_to_home ? "border-danger" : ""
                        }
                        {...register("distance_school_to_home", {
                          required: true,
                        })}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      En yakın sağlık kuruluşu ne kadar uzaklıkta km
                    </Form.Label>
                    <Col sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        className={
                          errors.distance_hospital_to_home
                            ? "border-danger"
                            : ""
                        }
                        {...register("distance_hospital_to_home", {
                          required: true,
                        })}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      Evde içme suyuna erişim var mı?
                    </Form.Label>
                    <Col sm={12} md={5}>
                      <Form.Check
                        label="Evet"
                        type="radio"
                        size="sm"
                        className={
                          errors.access_to_water ? "border-danger" : ""
                        }
                        {...register("access_to_water")}
                      />
                      <Form.Check
                        label="Hayır"
                        type="radio"
                        size="sm"
                        className={
                          errors.access_to_water ? "border-danger" : ""
                        }
                        {...register("access_to_water")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col lg={10} className="my-4">
                  <h5 className="mb-4 text-center">
                    Ortalama Aile gider bilgileri
                  </h5>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      Eğitim Masrafı
                    </Form.Label>
                    <Col sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        {...register("education_expense", {
                          required: true,
                        })}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      Ulaşım Masrafı
                    </Form.Label>
                    <Col sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        {...register("transportation_expense", {
                          required: true,
                        })}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      Mutfak Masrafı
                    </Form.Label>
                    <Col sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        {...register("food_expense", { required: true })}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      Giyim Masrafı
                    </Form.Label>
                    <Col sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        {...register("clothing_expense", { required: true })}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col lg={10} className="mb-4">
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      Yakıt ve Su giderleri
                    </Form.Label>
                    <Col sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        {...register("fuel_and_water_expense", {
                          required: true,
                        })}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      Ev giderleri
                    </Form.Label>
                    <Col sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        {...register("housing_expense", { required: true })}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      Beklenmeyen giderler
                    </Form.Label>
                    <Col sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        {...register("unexpected_expense", { required: true })}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column className="text-md-end">
                      Birikim
                    </Form.Label>
                    <Col sm={12} md={5}>
                      <Form.Control
                        type="text"
                        size="sm"
                        {...register("savings", { required: true })}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col lg={10} className="mb-4 text-end">
                  <Button
                    type="submit"
                    variant="success"
                    size="sm"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      "Kaydet"
                    )}
                  </Button>
                </Col>
                {/* <Calculate /> */}
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
      <DevTool control={control} />
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Tebrikler</Modal.Title>
        </Modal.Header>
        <Modal.Body>Anketi başarıyla tamamladınız.</Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="success" onClick={handleClose}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LivingWageSurvey;
