import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ isProtected }) => {
  const [userToken] = useSelector((state) => console.log(state));

  if (!isProtected) {
    return userToken ? <Navigate to="/" /> : <Outlet />;
  }

  if (!userToken) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
