import React from "react";
import { Col, Form, Row } from "react-bootstrap";
const Calculate = () => {
  return (
    <Col lg={10} className="mb-4">
      <Form.Group as={Row} className="mb-3">
        <Form.Label column className="text-end">
          Adil yaşam ücreti
        </Form.Label>
        <Col>
          <h6>Toplam giderler</h6>
          <Form.Control type="text" value="TL" size="sm" />
        </Col>
        <Col>
          <h6>Gelir elde eden kişi sayısı</h6>
          <Form.Control type="text" value="TL" size="sm" />
        </Col>
        <Col>
          <h6>Sonuç</h6>
          <Form.Control type="text" value="TL" size="sm" />
        </Col>
      </Form.Group>
    </Col>
  );
};

export default Calculate;
